import {UserAgentApplication} from  "msal";
// import axios from 'axios';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

let signInType;

var msalConfig = {
    auth: {
    clientId: '3bb50490-b10c-4a03-a53c-a51c6f8e681c', //This is your client ID Demo B2C
    authority: "https://allyfoundryb2c.b2clogin.com/allyfoundryb2c.onmicrosoft.com/B2C_1A_signup_signinAllyFoundry", //This is your tenant info
    validateAuthority: false,
    //redirectUri: "http://localhost:8233/",
    redirectUri: "https://workally.allyfoundry.com/",
    navigateToLoginRequestUrl: true,
    //loadFrameTimeout: 3000,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};


export const loginConfig = {
     scopes: ["https://allyfoundryb2c.onmicrosoft.com/workally/usage_data"]
};


export  const apiConfig = {
   scopes: ["https://allyfoundryb2c.onmicrosoft.com/workally/usage_data"]
   
};


export const msalApp = new UserAgentApplication(msalConfig);



function authRedirectCallBack(error, response) {
    if (error) {
        console.log(error);
    } else {
        if (response.tokenType === "id_token" && msalApp.getAccount() && !msalApp.isCallback(window.location.hash)) {
            console.log('id_token acquired at: ' + new Date().toString());
            
           /* msalApp.acquireTokenSilent(apiConfig).then(tokenResponse => {
                console.log("API token aquired silently: "+tokenResponse);
                return tokenResponse;
            }).catch(function(error) {
                console.log("Failed to acquire token silently. Error:"+error);
            }); */
        } else if (response.tokenType === "access_token") {
            console.log('access_token acquired at: ' + new Date().toString());
        } else {
            console.log("token type is:" + response.tokenType);
        }
    }
}



export function signIn() {
    if(isAuthenticated()) {
        return;
    } else {       
        msalApp.loginRedirect(loginConfig);
    }
    
}

export function isRedirectFromAuthentication() {
    if(msalApp.redirectResponse) {
        return true;
    } else {
        return false;
    }
}


export function getUserName() {
    if(msalApp.getAccount()){
        return msalApp.getAccount().name;
    } else {
        return null;
    }
}

export function isAuthenticated() {
    var userName = getUserName();
    return userName != null;
}

 

export function signOut() {
    msalApp.logout();
}

export function getSavedLocation() {
    return window.localStorage.getItem('original_url');
}


export function saveCurrentLocation() {
    var current_url = window.location.href.split(/[#?]/)[0];
    var current_query_string = window.location.search;
    window.localStorage.setItem('original_url', current_url); 
    window.localStorage.setItem('original_query_string', current_query_string);            
    console.log("encoding original_url as:"+current_url);
    console.log("encoding original_query_string as:"+current_query_string);
}

export function restorePreviousLocation() {
    var original_url = window.localStorage.getItem('original_url');
    var original_query_string = window.localStorage.getItem('original_query_string');
    var current_url = window.location.href.split(/[#?]/)[0];
    // var query_string = window.location.search;

  if(original_url && original_url != current_url) { 
    console.log("original url:"+original_url+" current url:"+current_url+" original query string:"+original_query_string);
    window.localStorage.removeItem('original_url');
    window.localStorage.removeItem('original_query_string');
    console.log("Redirecting to original url: "+original_url+original_query_string);
    window.location.href = original_url+original_query_string;
  }

  window.localStorage.removeItem('original_url');
  window.localStorage.removeItem('original_query_string');
}




//acquire a token silently with Promise
export function getAPIAccessToken(tokenRequest) {
    return msalApp.acquireTokenSilent(apiConfig).catch(function(error) {
        console.log("Failed to acquire token silently. Error:"+error);
        saveCurrentLocation();
        msalApp.acquireTokenRedirect(apiConfig);  
    });
   
   
}




    
