import { from, operators } from 'rxjs'
const { pluck, tap, map } = operators
import axios from 'axios'
import addId from './addId.js'
import {getAPIAccessToken } from '../../Server/src/AuthProvider';


const axiosInstance = axios.create({
  //baseURL: 'https://allysuitepublic.azurewebsites.net/api/',
  baseURL: 'https://workally.allyfoundry.com/api/',
  //baseURL: 'https://waprocwaweportal.azurewebsites.net/api/',
  //baseURL: 'https://localhost:5001/api/',
  timeout: 20000,
  crossDomain: true
})

axiosInstance.interceptors.request.use((config) => {
  return getAPIAccessToken().then(accessRequest => {
    config.headers.Authorization = `Bearer ${accessRequest.accessToken}`;
    return Promise.resolve(config);
  }).catch(error => {
    console.log("Error getting API token for axios"+error);
  });
}, (error) => {
  return Promise.reject(error);
});

const tenMin = 1000 /* ms */ * 60 /* sec */ * 10

const axiosBackOfficeInstance = axios.create({
  //baseURL: 'https://allysuitepublic.azurewebsites.net/api/',
  baseURL: 'https://waprocwawebackoffice.azurewebsites.net/api/',
  //baseURL: 'https://localhost:44363/api/',
  timeout: 20000,
  crossDomain: true
})

axiosBackOfficeInstance.interceptors.request.use((config) => {
  return getAPIAccessToken().then(accessRequest => {
    config.headers.Authorization = `Bearer ${accessRequest.accessToken}`;
    return Promise.resolve(config);
  }).catch(error => {
    console.log("Error getting API token for axios"+error);
  });
}, (error) => {
  return Promise.reject(error);
});

export default class fetchWithCache {

  fetchBackOffice(url, axiosOptions) {
    const options = {...axiosOptions, ...{method: 'get'}, ...{url}}
    return from(axiosBackOfficeInstance(options));
  }

  postBackOffice(url, axiosOptions, data) {
    const options = {...axiosOptions, ...{method: 'post'}, ...{url}, ...{data}}
    return from(axiosBackOfficeInstance(options));
  }

  fetchPortal(url, axiosOptions, invalidateCache) {
    url = url
    const options = {...axiosOptions, ...{method: 'get'}, ...{url}}
    if (!invalidateCache && cache[url] != undefined) {
      const diff = Date.now() - cache[url].lastPulled
      if (diff < tenMin) {
        return from(
          Promise.resolve().then(() => {
            return cache[url].value
          })
        )
      }
    }
    return from(
      axiosInstance(options)
    ).pipe(
      pluck('data'),
      map((response) => {
        if (!response)
          return response;
        const id = addId(response)
        if (id) {
          return {id, ...response}
        } else if(response.results) {
          return {...response, results: response.results.map((result) => {
            const id = addId(result)
            return {id, ...result}
          })}
        } else {
          return response
        }
      }),
      tap((response) => {
        cache[options.url] = {
          lastPulled: Date.now(),
          value: response
        }
        if (response.results && Array.isArray(response.results)) {
          response.results.forEach((item) => {
            if (item.url) {
              cache[item.url] = {
                lastPulled: Date.now(),
                value: item,
              }
            }
          })
        }
      }),
    )
  }

}



const cache = {}
